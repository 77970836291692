import React, { useCallback, useMemo } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { useIsMobile, useSelectedCompany } from '@fingo/lib/hooks';
import { BooleanButton } from '@fingo/lib/components/buttons';
import { openSiiForm } from '@fingo/lib/helpers/sii-credentials-form-toggle';
import { useCompanyCredentials } from '../../hooks';

const RegistrationProgressBanner = () => {
  const isMobile = useIsMobile();
  const selectedCompany = useSelectedCompany();
  const [open, setOpen] = React.useState(false);
  const [openedStep, setOpenedStep] = React.useState(null);
  const toggle = useCallback(
    ({ step }) => {
      if (step.avoidFingoDialog) {
        openSiiForm(selectedCompany);
      } else {
        setOpen((prev) => !prev);
      }
      setOpenedStep(step.id);
    },
    [selectedCompany],
  );
  const registrationSteps = useCompanyCredentials();
  const steps = useMemo(
    () => registrationSteps
      .sort((a, b) => Number(b.isOk) - Number(a.isOk))
      .filter((doc) => doc.showInProgressBanner),
    [registrationSteps],
  );
  return (
    <>
      <Stack
        backgroundColor="background.light"
        justifyContent="center"
        direction="row"
        alignItems="center"
        px={4}
        sx={{
          position: 'relative',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          height: 50,
        }}
      >
        {!isMobile ? (
          <>
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{
                position: 'absolute',
                left: 0,
                px: 4,
              }}
            >
              ¡Completa tu registro!
            </Typography>
            <Stack direction="row" justifyContent="center">
              {steps.map((step) => (
                <BooleanButton
                  key={step.id}
                  id={`progress-${step.id}`}
                  title={step.shortTitle || step.title}
                  isOk={step.isOk}
                  disabled={step.disabled}
                  disabledTooltipText={step.disabledTooltipText}
                  handleOnClick={() => {
                    toggle({ step });
                  }}
                />
              ))}
            </Stack>
          </>
        ) : (
          <Link
            variant="h5"
            fontWeight={600}
            sx={{
              py: 1,
            }}
            href="/app/profile/credentials"
          >
            ¡Completa tu registro!
          </Link>
        )}
      </Stack>
      {registrationSteps.map((step) => {
        if (step.id !== openedStep) return null;
        return <step.DialogComponent open={open} setOpen={() => toggle({ step })} />;
      })}
    </>
  );
};

export default RegistrationProgressBanner;
