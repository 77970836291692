import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useGetUser, useSnackBars } from '@fingo/lib/hooks';
import { useMutation } from '@apollo/client';
import { REQUEST_COLLECTION_PLAN_DEMO } from '@fingo/lib/graphql/collection/mutations';

const KobrosAskForDemoButton = ({ text }) => {
  const user = useGetUser();
  const history = useHistory();
  const { addAlert } = useSnackBars();

  const userName = user ? (`${user.firstName} ${user.lastName}`) : null;
  const [requestCollectionPlanDemo, { loading }] = useMutation(
    REQUEST_COLLECTION_PLAN_DEMO,
    {
      variables: {
        name: userName,
        email: user?.mailProvided,
        phoneNumber: user?.phoneNumber,
      },
      onCompleted: () => {
        addAlert({
          id: 'request-more-info',
          message: 'Te enviaremos información a tu correo',
          severity: 'success',
          color: 'success',
        });
        history.push('/app/sales/collection/collection-landing/landing-contact');
      },
    },
  );
  return (
    <LoadingButton
      variant="outlined"
      color="text"
      size="small"
      loading={loading}
      sx={{ borderRadius: '20px', height: 30, fontWeight: 'normal' }}
      id="request-more-info-button"
      onClick={() => {
        requestCollectionPlanDemo();
      }}
    >
      <Typography variant="body1" component="span">
        { text }
      </Typography>
    </LoadingButton>
  );
};

KobrosAskForDemoButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default KobrosAskForDemoButton;
