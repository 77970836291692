import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { GET_USER, CREATE_BANK_ACCOUNT } from '@fingo/lib/graphql';
import { useTranslation } from 'react-i18next';
import { useGetUser, useIsMobile, useAvailableBanks, useBankAccountTypes } from '@fingo/lib/hooks';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import makeStyles from '@mui/styles/makeStyles';
import ValidationTextField from '@fingo/lib/components/inputs/ValidationTextFieldInput';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import getErrorMessage from './helper';

const useStyles = makeStyles({
  header: {
    marginBottom: '1rem',
  },
  label: {
    marginBottom: '0.5rem',
  },
  input: {
    width: '100%',
    marginBottom: '1rem',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  buttonsContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  error: {
    marginBottom: '0.5rem',
    marginLeft: '0.5rem',
  },
});

const defaultFormData = (companyName) => ({
  number: {
    value: '',
    error: '',
  },
  name: {
    value: companyName,
    error: '',
  },
  email: {
    value: '',
    error: '',
  },
  bank: {
    value: 'choose',
    error: '',
  },
  accountType: {
    value: 'choose',
    error: '',
  },
});

const NewAccount = ({ cancel, onAccountCreate, setDialogAlert, accounts }) => {
  const { t } = useTranslation();
  const { selectedCompany } = useGetUser();
  const country = useGetCountryFromUrl();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { accountTypes, loadingAccountTypes } = useBankAccountTypes(country.id);
  const { banks } = useAvailableBanks(country.id);
  const [formData, setFromData] = useState(defaultFormData(selectedCompany.name));
  const [_createNewAccount, { loading }] = useMutation(
    CREATE_BANK_ACCOUNT,
    {
      refetchQueries: [GET_USER],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        onAccountCreate(data.createBankAccount.bankAccountCreated);
        setDialogAlert({
          message: 'Cuenta creada exitosamente',
          severity: 'success',
          show: true,
          timeout: 10000,
        });
      },
    },
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFromData((oldData) => ({ ...oldData, [name]: { ...oldData[name], value } }));
    if (name === 'bank' || name === 'accountType') {
      setDialogAlert({
        message: '',
        severity: '',
        show: false,
      });
    }
  };

  const createNewAccount = async () => {
    const { number, name, email, bank, accountType } = formData;
    const errorMessage = getErrorMessage(formData, accounts);
    if (errorMessage) {
      setDialogAlert({
        message: errorMessage,
        severity: 'error',
        show: true,
      });
      return;
    }
    setDialogAlert({
      message: '',
      severity: '',
      show: false,
    });
    _createNewAccount({
      variables: {
        bankName: bank.value,
        accountEmail: email.value,
        accountName: name.value,
        accountNumber: number.value,
        accountRut: `${selectedCompany.masterEntity.displayNationalIdentifier.replace(/\./g, '')}`,
        accountType: accountType.value,
      },
    });
  };

  return (
    <>
      <Grid container alignItems="center" className={classes.header}>
        <Grid item xs={2}>
          <IconButton onClick={cancel} size="large">
            <ArrowBackIos />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography color="primary" variant="h6">
            Agrega tu cuenta bancaria
          </Typography>
        </Grid>
      </Grid>
      <Typography className={classes.label}><b>{t('National identifier')}</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="rut"
        value={selectedCompany.masterEntity.rut}
        disabled
      />
      <Typography className={classes.label}><b>Titular de la cuenta</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="name"
        value={formData.name.value}
        error={formData.name.error !== ''}
        helperText={formData.name.error}
        onChange={handleChange}
      />
      <Typography className={classes.label}><b>{t('account number')}</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="number"
        type="number"
        value={formData.number.value}
        error={formData.number.error !== ''}
        helperText={formData.number.error}
        onChange={handleChange}
      />
      <Typography className={classes.label}><b>Banco</b></Typography>
      <FormControl variant="outlined" fullWidth>
        <Select
          className={classes.input}
          name="bank"
          value={formData.bank.value}
          onChange={handleChange}
        >
          <MenuItem value="choose" disabled>Elige un banco</MenuItem>
          {banks?.getBankOptionsByCountry.map((bank) => (
            <MenuItem key={bank.name} value={bank.name}>{bank.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography className={classes.label}><b>Tipo de cuenta</b></Typography>
      <FormControl variant="outlined" fullWidth>
        <Select
          className={classes.input}
          name="accountType"
          value={formData.accountType.value}
          onChange={handleChange}
        >
          <MenuItem value="choose" disabled>Elige un tipo de cuenta</MenuItem>
          {!loadingAccountTypes && accountTypes?.getBankAccountTypesByCountry.map((type) => (
            <MenuItem key={type.name} value={type.name}>{type.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography className={classes.label}><b>Email</b></Typography>
      <ValidationTextField
        className={classes.input}
        variant="outlined"
        name="email"
        value={formData.email.value}
        onChange={handleChange}
        validationtype="email"
      />
      <div className={isMobile ? classes.buttonsContainerMobile : classes.buttonsContainer}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={createNewAccount}
          endIcon={loading ? <CircularProgress size={16} /> : null}
          disabled={loading}
        >
          Agregar cuenta
        </Button>
      </div>
    </>
  );
};

NewAccount.propTypes = {
  cancel: PropTypes.func.isRequired,
  onAccountCreate: PropTypes.func.isRequired,
  setDialogAlert: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    bankName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
  })).isRequired,
};

export default NewAccount;
