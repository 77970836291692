import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const ErrorBox = ({ children, ...props }) => (
  <Box
    {...props}
    sx={{
      border: '1px solid red',
      backgroundColor: '#ffe6e6',
      color: 'red',
      padding: 2,
      borderRadius: 2,
      textAlign: 'center',
    }}
  >
    {children}
  </Box>
);

ErrorBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBox;
