import React from 'react';
import { IconButton } from '@mui/material';
import { useBooleanState } from '@fingo/lib/hooks';
import EmbedYouTubeDialog from '@fingo/lib/components/embedYouTubeDialog.js/EmbedYoutubeDialog';
import { getHelpVideoLink } from '@fingo/lib/constants';
import Info from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import { KobrosVideoDialog } from '../dialogs';

const KobrosActionTutorial = ({ video, children }) => {
  const [openVideo, toggleOpenVideo] = useBooleanState();
  const [open, setOpen] = useBooleanState();

  return (
    <>
      <IconButton
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          height: 18,
          margin: 0.3,
          marginLeft: 1,
          paddingRight: 0,
          width: '10px',
          color: 'gray',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          boxShadow: 'none',
        }}
        onClick={() => setOpen()}
        id="kobros-action-tutorial-button"
      >
        <Info />
      </IconButton>

      <KobrosVideoDialog open={open} setOpen={setOpen} video={video}>
        {children}
      </KobrosVideoDialog>
      <EmbedYouTubeDialog
        id="kobros-action-tutorial"
        videoId={getHelpVideoLink(video)}
        openVideo={openVideo}
        toggleOpenVideo={toggleOpenVideo}
      />
    </>
  );
};

KobrosActionTutorial.propTypes = {
  video: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default KobrosActionTutorial;
