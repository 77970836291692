import React, { useMemo, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { ACTIVATE_COLLECTION_PLAN } from '@fingo/lib/graphql/collection/mutations';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { hasCollectionAvailable } from '@fingo/lib/helpers/plans';
import { useGetUser } from '@fingo/lib/hooks';
import { DEFAULT_COLLECTION_PLAN_ID } from '../../features/collection/constants/collection';
import CollectionWaiting from '../../features/collection/components/CollectionWaiting';

const KobrosAcceptTermsButton = () => {
  const [slowMutationWarning, setSlowMutationWarning] = useState(false);
  const history = useHistory();
  const user = useGetUser();
  const canAccept = useMemo(
    () => !hasCollectionAvailable(user),
    [user],
  );
  const timeLimit = 2000;

  const redirect = () => {
    history.push('/app/sales/collection/home');
  };
  const [acceptTerms, { loading }] = useMutation(ACTIVATE_COLLECTION_PLAN, {
    variables: {
      planId: DEFAULT_COLLECTION_PLAN_ID,
    },
    onCompleted: () => {
      redirect();
    },
  });

  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setSlowMutationWarning(true);
      }, timeLimit);
    } else {
      setSlowMutationWarning(false);
    }

    return () => clearTimeout(timer);
  }, [loading]);

  return (
    <>
      {
      canAccept ? (
        <LoadingButton
          loading={loading}
          variant="contained"
          color="kobros"
          size="small"
          sx={{ borderRadius: '20px', height: 30, fontWeight: 'normal' }}
          onClick={acceptTerms}
          id="explore-kobros-button"
        >
          <Typography variant="body1" component="span">
            Explorar koBros
          </Typography>
        </LoadingButton>
      )
        : (
          <LoadingButton
            loading={loading}
            variant="contained"
            color="kobros"
            size="small"
            sx={{ borderRadius: '20px', height: 30, fontWeight: 'normal' }}
            onClick={redirect}
            id="go-to-kobros-button"
          >
            <Typography variant="body1" component="span">
              Ir a koBros
            </Typography>
          </LoadingButton>
        )
    }

      <CollectionWaiting open={slowMutationWarning} />
    </>
  );
};

export default KobrosAcceptTermsButton;
