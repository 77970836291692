import React from 'react';
import PropTypes from 'prop-types';
import { useBooleanState } from '@fingo/lib/hooks';
import { Stack, Typography } from '@mui/material';
import WarningAmber from '@mui/icons-material/WarningAmber';
import Clear from '@mui/icons-material/Clear';

const AlertBanner = ({ isRegisterBannerShown }) => {
  const [pressed, togglePressed] = useBooleanState();
  if (pressed) return null;
  return (
    <>
      <Stack
        backgroundColor="#F2E8B4"
        justifyContent="center"
        direction="row"
        alignItems="center"
        spacing={2}
        px={4}
        sx={{
          position: 'absolute',
          top: isRegisterBannerShown ? 75 : 10,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        <WarningAmber />
        <Typography sx={{ marginTop: 0.7, marginBottom: 0.7 }} variant="h6">
          Informamos que el SII está con intermitencias,
          por lo que algunas operaciones podrían demorar más de lo normal.
          Lamentamos las molestias que esto pueda ocurrir.
        </Typography>
        <Clear onClick={togglePressed} sx={{ cursor: 'pointer' }} />
      </Stack>
    </>
  );
};

AlertBanner.propTypes = {
  isRegisterBannerShown: PropTypes.bool.isRequired,
};

export default AlertBanner;
