import React from 'react';
import { Tooltip, Typography, Badge, Box, IconButton } from '@mui/material';
// import useGetPendingCompanyRestrictions from '@fingo/lib/hooks/useGetPendingCompanyRestrictions';
// import { useSelectedCompany } from '@fingo/lib/hooks';
import Error from '@mui/icons-material/Error';

const CompanyRestrictionsButton = () => {
  // const company = useSelectedCompany();
  // const { pendingCompanyRestrictions: restrictionList }
  // = useGetPendingCompanyRestrictions(company
  //   .id);

  // if (restrictionList.length === 0) { Descomentar cuando se arregle CompanyEvaluationRestrictions
  const isFixed = true;
  if (isFixed) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Tooltip
        title={(
          <Box>
            <Typography variant="body1">Por regularizar:</Typography>
            {/* {restrictionList.map((restriction) => (
              <Typography key={restriction.label} variant="body1">• {restriction.label}</Typography>
            ))} */}
          </Box>
        )}
        placement="left"
      >
        <Badge
          // badgeContent={restrictionList.length}
          color="primary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          overlap="circular"
        >
          <IconButton
            variant="contained"
            color="warning"
            size="medium"
            sx={{
              width: 40,
              height: 40,
              display: 'flex',
            }}
          >
            <Error size="medium" fontSize="large" />
          </IconButton>
        </Badge>
      </Tooltip>
    </Box>
  );
};

export default CompanyRestrictionsButton;
