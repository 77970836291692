import React from 'react';
import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import OpenInNew from '@mui/icons-material/OpenInNew';

import { getCdnUrl } from '@fingo/lib/helpers';

const KobrosDownloadGuideButton = ({ text }) => (
  <Button
    variant="outlined"
    color="text"
    size="small"
    sx={{ borderRadius: '20px', height: 30, fontWeight: 'normal' }}
    id="collection-guide-button"
    onClick={() => window.open(getCdnUrl('/documents/kobros_guide.pdf'), '_blank')}
  >
    <Typography variant="body1" component="span" marginRight={1}>
      { text }
    </Typography>
    <OpenInNew fontSize="5" color="text" />
  </Button>
);

KobrosDownloadGuideButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default KobrosDownloadGuideButton;
